import './styles/index.css';
import { useState, useEffect, useContext, useRef } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import { Autocomplete, Tooltip, Pagination, FormControl, RadioGroup, FormControlLabel, Radio } from "@mui/material"

// Accordian
import CircularProgress from '@mui/material/CircularProgress';

import { getAllBrands } from "../../../services/brandService";
import { deleteFaqById } from "../../../services/faqService"
import { getFranchiseByBrandId } from "../../../services/franchises"
import { ERROR_COMPONENTS, NOTIFICATION_TYPES, ROW_COUNT_PER_PAGE, TOLL_FREE } from "../../../constants/common";
import { USER_TYPES } from "../../../constants/users";
import ErrorView from "../../../components/ErrorView";
import FaqModal from "./components/faqModal/FaqModal";
import RenderInput, { renderBrandOption } from "../../../components/servicetitan/RenderInput"
import ConfirmationModal from "./components/confirmationModal/ConfirmationModal";
import { tabName, faqGetReqTypes } from "../../../constants/faq";
import { useDebounce } from "../../../utils/UseDebounce";
import { handleFaqs, handleAllFranchiseFaqs } from "../../../state-services/faq";
import { UserContext } from '../../../contexts/User';
import { CommonContext } from '../../../contexts/CommonContextProvider';
import { sortBrandsAlphabetically, sortFranchisesAlphabetically } from '../../../utils/miscellaneous';
import { handleGetKeywords } from '../../../state-services/faq/faq';
import FaqCard from './components/faqCard/FaqCard';
import TemplateFaqCard from './components/faqCard/TemplateFaqCard';
import CustomTextField from '../../../components/CustomTextField';

export const FAQ_TYPE_SECTIONS = {
    BRAND: {
        index: 0,
        section: "brand",
        title: "Brand",
    },
    FRANCHISE: {
        index: 1,
        section: "franchise",
        title: "Franchise",
    }
}

export default function FaqSection() {
    const { franchises: allFranchises } = useContext(CommonContext);
    const { me } = useContext(UserContext)
    const [dialogOpen, setDialogOpen] = useState(false);
    const [brands, setBrands] = useState([])
    const [brandDetail, setBrandDetail] = useState(null)
    const [faqs, setFaqs] = useState([])
    const [faq, setFaq] = useState({})
    const [franchises, setFranchises] = useState([])
    const [franchiseDetail, setFranchiseDetail] = useState(null)
    const [franchiseName, setFranchiseName] = useState("")
    const [searchValue, setSearchValue] = useState("")
    const [faqTypeSection, setFaqTypeSection] = useState(tabName.brand)
    const [expanded, setExpanded] = useState("")
    const [deleteFaqId, setDeleteFaqId] = useState("")
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [level, setLevel] = useState(parseInt(me?.level))
    const [loading, setLoading] = useState(false)
    const { showNotification } = useContext(CommonContext);
    const [currentPage, setCurrentPage] = useState(1)
    const [fetchType, setFetchType] = useState(faqGetReqTypes.Brand)
    const [faqCount, setFaqCount] = useState({ all: 0, active: 0, inactive: 0 })
    const [refresh, setRefresh] = useState(false)
    const [keywords, setKeywords] = useState([])
    const [isKeywordsLoading, setIsKeywordsLoading] = useState(false)
    const [isKeywordsError, setIsKeywordsError] = useState(false)
    const [selectedSearchKeywords, setSelectedSearchKeywords] = useState([])
    const isInitialDataLoading = useRef(true)

    const debouncedSearchTerm = useDebounce(searchValue, 1000);
    const countPerPage = ROW_COUNT_PER_PAGE

    useEffect(() => {
        getInitData()
        handleGetKeywords(setKeywords, setIsKeywordsLoading, setIsKeywordsError)
    }, []);

    useEffect(() => {
        fetchFaqs()
    }, [brandDetail, franchiseDetail, countPerPage, faqTypeSection, refresh, debouncedSearchTerm, selectedSearchKeywords, currentPage]);

    useEffect(() => {
        if (fetchType === faqGetReqTypes.AllFranchise && !isInitialDataLoading.current  ) {
            fetchAllFranchiseFaqs(franchises)
        }
    }, [isInitialDataLoading.current,countPerPage, faqTypeSection, refresh, debouncedSearchTerm, selectedSearchKeywords, currentPage])

    useEffect(() => {
        if (me) setLevel(me.level)
    }, [me]);

    const getInitData = async () => {
        setLoading(true)
        setFaq([])
        try {
            const brandsData = await getAllBrands()
            setBrands(brandsData?.brands)
            const firstBrandData = brandsData?.brands[0]
            setBrandDetail(firstBrandData)
            if (firstBrandData.brand_id) {
                const franchises = await getFranchiseByBrandId(firstBrandData?.brand_id)
                const franchisesWithoutTollFree = franchises?.filter?.((f) => f?.franchise_id !== TOLL_FREE.franchiseIdNumber)
                setFranchises(franchisesWithoutTollFree)
            }
        } catch (error) {
            setLoading(false)
        }finally{
            isInitialDataLoading.current = false
        }
    }

    const fetchFaqs = () => {
        setFaq([])
        const brandId = brandDetail?.id ? brandDetail.id : ""
        const franchiseId = franchiseDetail?.id ? franchiseDetail.id : ""
        if (brandDetail && (fetchType !== faqGetReqTypes.AllFranchise)) {
            let formatSearchValue  = searchValue.trim()
            handleFaqs(brandId, franchiseId, fetchType, formatSearchValue, selectedSearchKeywords, setLoading, setFaqs, showNotification, setFaqCount, currentPage, countPerPage)
        }
    }

    const fetchAllFranchiseFaqs = (franchises) => {
        setFaq([])
        setFaqCount({ all: 0, active: 0, inactive: 0 })
        let franchiseIds = franchises?.map?.((f) => f?.id)
        let formatSearchValue  = searchValue.trim()
        handleAllFranchiseFaqs(franchiseIds, formatSearchValue, selectedSearchKeywords, setLoading, setFaqs, showNotification, setFaqCount, currentPage, countPerPage)
    }

    const handleDialogOpen = () => {
        setFaq({})
        if (faqTypeSection === tabName.franchise && !franchiseDetail) {
            setDialogOpen(false);
        } else {
            setDialogOpen(true);
        }
    };

    const fetchFaqsBySearchValues = (keyword, page) => {
        const brandId = brandDetail?.id ? brandDetail.id : ""
        const franchiseId = franchiseDetail?.id ? franchiseDetail.id : ""
        if (brandDetail && (fetchType !== faqGetReqTypes.AllFranchise)) {
            handleFaqs(brandId, franchiseId, fetchType, keyword, selectedSearchKeywords, setLoading, setFaqs, showNotification, setFaqCount, page, countPerPage)
        }
    }

    const handleOnEdit = (data, frName) => {
        setFaq(data)
        setFranchiseName(frName)
        setDialogOpen(true);
    }

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const brandOnChange = async (brand) => {
        try {
            setCurrentPage(1)
            setSearchValue("")
            setBrandDetail(brand)
            setFranchises([])
            let fetchType = ""
            if (faqTypeSection === tabName.brand) {
                fetchType = faqGetReqTypes.Brand
            }
            if (faqTypeSection === tabName.franchise) {
                fetchType = faqGetReqTypes.AllFranchise
            }
            setFetchType(fetchType)
            const franchises = await getFranchiseByBrandId(brand?.brand_id)
            if (franchises) {
                const franchisesWithoutTollFree = franchises?.filter?.((f) => f?.franchise_id !== TOLL_FREE.franchiseIdNumber)
                setFranchises(franchisesWithoutTollFree)
                setFranchiseDetail(null)
                setFranchiseName("")
            } else {
                setFranchises([])
            }
            if(fetchType === faqGetReqTypes.AllFranchise) {
                fetchAllFranchiseFaqs(franchises)
            }
        } catch (error) {
            setFranchises([])
            setFranchiseDetail(null)
            setFranchiseName("")
            setLoading(false)
        }
    }

    const franchiseOnChange = async (data) => {
        try {
            setCurrentPage(1)
            setSearchValue("")
            setFaqs([])
            let fetchType = null
            if (data !== null) {
                fetchType = data?.franchise_id ? faqGetReqTypes.Franchise : faqGetReqTypes.Brand
            }else{
                fetchType = faqGetReqTypes.AllFranchise
                fetchAllFranchiseFaqs(franchises)
            }
            setFetchType(fetchType)
            setFranchiseDetail(data)
        } catch (error) {
            setLoading(false)
        }
    }

    const refreshFaq = async () => {
        try {
            setCurrentPage(1)
            setFaqs([])
            setRefresh(!refresh)
        } catch (error) {
            setLoading(false)
        }
    }

    const handleSearch = (e) => {
        let keyWord = e.target.value.replace(/^\s+/g, '')
        currentPage !== 1 && setCurrentPage(1)
        setSearchValue(keyWord)
    }

    const tabOnClick = async (tab) => {
        try {
            setLoading(true)
            setFaqTypeSection(tab)
            setCurrentPage(1)
            setExpanded("")
            setFranchiseDetail(null)
            setFranchiseName("")
            setSearchValue("")
            let fetchType = ""
            if (tab === tabName.brand) {
                fetchType = faqGetReqTypes.Brand
            }
            if (tab === tabName.franchise) {
                fetchType = faqGetReqTypes.AllFranchise
            }
            setFetchType(fetchType)
        } catch (error) {
            setLoading(false)
        }
    }

    const deleteOnClick = (id) => {
        setDeleteFaqId(id)
        setConfirmDialogOpen(true)
    }

    const handleDeleteFAQ = async () => {
        try {
            if (deleteFaqId !== "") {
                await deleteFaqById(deleteFaqId)
                setConfirmDialogOpen(false)
                showNotification({ message: "FAQ has been deleted successfully", type: NOTIFICATION_TYPES.SUCCESS })
                refreshFaq()
            }
        } catch (error) {
            showNotification({ message: "Unable to delete FAQ at this time", type: NOTIFICATION_TYPES.ERROR })
        }
    }

    const handleConfirmDialogClose = () => {
        setConfirmDialogOpen(false);
    };

    function handlePagination(v) {
        setCurrentPage(v)
        fetchFaqsBySearchValues(searchValue, v)
    }

    const frInputOnChange = (data) => {
        if (data.target.value === "") {
            setFetchType(faqGetReqTypes.AllFranchise)
            setFranchiseDetail(null)
            setCurrentPage(1)
        }
    }

    return <Grid item xs={12}>
        <Box className="bg-shadow bg-white" sx={{ paddingInline: "1em" }}>
            <Box className="tabs" sx={{ marginTop: "0.25em", marginBottom: "0.5em" }}>
                <FormControl>
                    <RadioGroup
                        row
                        value={faqTypeSection}
                        name="type-radio-buttons-group"
                        onChange={(e) => (!loading && tabOnClick(e?.target?.value))}>
                        {Object.values(FAQ_TYPE_SECTIONS).map((t) => <FormControlLabel
                            key={t.index}
                            value={t.section}
                            control={<Radio sx={{ '&, &.Mui-checked': { color:'#00a79d', }, "&.Mui-disabled": { color: "grayText" } }}  inputProps={{ "data-test": `faq_type_${t.section}` }} />}
                            label={t.title}
                        />)}
                    </RadioGroup>
                </FormControl>
            </Box>
            <Box sx={{ width: "100%" }}>
                <Box sx={{ display: "flex" }}>
                    <Box sx={{ flexGrow: 2 }} />
                    <Autocomplete
                        sx={{
                            minWidth: "300px",
                            maxWidth: "300px", flexGrow: 2
                        }}
                        size="small"
                        noOptionsText="No brands"
                        disabled={brands === [] || loading}
                        disablePortal
                        disableClearable
                        options={sortBrandsAlphabetically(brands)}
                        getOptionLabel={(brand) => brand.brand_name}
                        renderOption={renderBrandOption}
                        value={(brands?.length > 0 && brandDetail) ? brandDetail : null}
                        onChange={(_, brand) => {
                            brandOnChange(brand);
                        }}
                        renderInput={(params) => <CustomTextField {...params} inputProps={{ ...params.inputProps, "data-test": "faq_brand" }} label="Brand" />} />
                    {faqTypeSection !== tabName.brand &&
                        <Autocomplete
                            sx={{ minWidth: "400px", maxWidth: "400px", flexGrow: 2, marginLeft: 2 }}
                            size="small"
                            noOptionsText="No Franchises"
                            disabled={franchises === [] || loading}
                            disablePortal
                            disableClearable = {false}
                            options={sortFranchisesAlphabetically(franchises)}
                            getOptionLabel={(franchise) => franchise.franchise_name}
                            renderOption={(props, franchise) => <RenderInput {...props} key={franchise?.id} content={franchise?.franchise_name} data-test={`faq_franchise_option_${franchise?.id}`} />}
                            value={(franchises?.length > 0 && franchiseDetail) ? franchiseDetail : null}
                            onChange={(_, franchise) => { franchiseOnChange(franchise); }}
                            renderInput={(params) => <CustomTextField {...params} inputProps={{ ...params.inputProps, "data-test": "faq_franchises" }} label="Franchise" onChange={(value) => frInputOnChange(value)} />} />}
                    <Autocomplete
                        sx={{ minWidth: "300px", maxWidth: "300px", marginLeft: "1em" }}
                        size="small"
                        disablePortal
                        multiple={true}
                        noOptionsText={(isKeywordsError) ? "Temporary error" : "No matching keywords"}
                        loading={(isKeywordsLoading)}
                        options={keywords?.map?.((v) => v?.keyword)}
                        getOptionLabel={(keyword) => keyword}
                        renderOption={(props, keyword) => <RenderInput {...props} key={keyword} content={keyword} data-test={`faq_keyword_option_${keyword}`} />}
                        value={selectedSearchKeywords}
                        onChange={(_, keywords) => {
                            setSelectedSearchKeywords(keywords);
                        }}
                        renderInput={(params) => {
                            let trimmedValue = params.inputProps.value?.trimStart();
                            return (
                                <CustomTextField
                                    {...params}
                                    inputProps={{ 
                                        ...params.inputProps,
                                        value: trimmedValue, 
                                        "data-test": "faq_keyword" 
                                    }}
                                    label="Keywords" />
                            )
                        }
                        }
                    />
                    <CustomTextField
                        id="outlined-basic"
                        className="search-field"
                        inputProps={{ "data-test": "faq_search" }}
                        sx={{ marginLeft: 2 }}
                        label="Search By Q&A"
                        size="small"
                        variant="outlined"
                        onChange={handleSearch}
                        value={searchValue}
                        disabled={loading}
                        InputProps={{
                            endAdornment: searchValue ? (
                                <IconButton size="small" onClick={() => setSearchValue("")}>
                                    <ClearIcon />
                                </IconButton>
                            ) : undefined
                        }}
                    />
                    {(level === USER_TYPES.admin.level || level === USER_TYPES.superAdmin.level) &&
                        <span>
                            <Tooltip title={(faqTypeSection === tabName.franchise && !franchiseDetail) ? "Select a franchise before add the franchise related FAQ" : "Add New"}>
                                <span>
                                    <IconButton
                                        inputProps={{ "data-test": "faq_add" }}
                                        aria-label="expand" size="small"
                                        className={(faqTypeSection === tabName.franchise && !franchiseDetail) ? "icon-btn-disabled" : "icon-btn"}
                                        onClick={() => handleDialogOpen()} disabled={loading}
                                    >
                                        <AddIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </span>}
                </Box>
            </Box>
            {loading ?
                <div className="loading-wrapper">
                    <CircularProgress size={30} color="inherit" />
                </div> :
                <>
                    <div className="accordian-wrapper">
                        {faqs.length > 0 ?
                            faqs.map((faq, i) => (
                                faq.is_template_faq
                                    ?
                                    <TemplateFaqCard i={i} expanded={expanded} setExpanded={setExpanded} faq={faq} allFranchises={allFranchises} handleOnEdit={handleOnEdit} deleteOnClick={deleteOnClick} isFranchiseSelected={faqTypeSection === tabName.franchise && !franchiseDetail} shouldShowFranchiseName={fetchType === faqGetReqTypes.AllFranchise ? true : false} />
                                    :
                                    <FaqCard i={i} expanded={expanded} setExpanded={setExpanded} faq={faq} allFranchises={allFranchises} handleOnEdit={handleOnEdit} deleteOnClick={deleteOnClick} isFranchiseSelected={faqTypeSection === tabName.franchise && !franchiseDetail} isEditEnabled={(level === USER_TYPES.admin.level || level === USER_TYPES.superAdmin.level)} shouldShowFranchiseName={fetchType === faqGetReqTypes.AllFranchise ? true : false} />
                            )) :
                            <div className="no-record-div">
                                <ErrorView type={ERROR_COMPONENTS.NOT_FOUND} title='No FAQs Found' />
                            </div>
                        }
                    </div>
                </>}
        </Box>
        <Pagination siblingCount={3} page={currentPage} onChange={(e, v) => !loading && handlePagination(v)} className="pagination-bar"
            count={Math.ceil(faqCount.all / countPerPage)} color="standard" disabled={loading} />
        <FaqModal
            refresh={refreshFaq}
            onClose={handleDialogClose}
            faq={faq}
            open={dialogOpen}
            franchise={franchiseDetail}
            franchiseName={franchiseName}
            brand={brandDetail}
            showNotification={showNotification}
            keywords={keywords}
            isKeywordsLoading={isKeywordsLoading}
            isKeywordsError={isKeywordsError} />
        <ConfirmationModal onClose={handleConfirmDialogClose} open={confirmDialogOpen} handleDeleteFAQ={handleDeleteFAQ} />
    </Grid>;
}
